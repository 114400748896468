<template>
    <fragment>        
        <div class="item mb-3" v-for="(item, index) of courseData" :key="index">
            <div class="course-card">
                <div class="course-card-thumbnail ctoverlay">
                    <img :src="item.image_name" :alt="item.title">
                    <span class="catagroy">{{ item.category }}</span> 
                </div>
                <div class="course-card-body">
                    <div class="course-card-info">
                        <div>                                                       
                            <div class="star-rating">
                                <span class="avg" v-if="item.avg_ratings > 0">{{ item.avg_ratings }}</span> 
                                <RatingStar :avgRating="item.avg_ratings" />   
                            </div>
                        </div>                        
                    </div>
                    
                    <h4><router-link to="">{{ item.title }}</router-link></h4>
                    <div class="course_content" v-if="item.short_content" v-html="item.short_content"></div>
                    <!-- <div class="course_content" v-if="item.short_content" v-html="item.short_content.substring(0, 100)"></div> -->
                    <div class="course-card-footer">
                        <h5> <i class="uil uil-clipboard-alt"></i>{{ item.total_topics }} {{ pageContent('topics') }}</h5>
                        <h5 class="last">
                            <!-- <div class="course-button">
                                <router-link :to="`/user/course/${item.id}`" v-if="storeToken">{{ pageContent('view_details') }} <i class="icon-feather-chevrons-right"></i></router-link>
                                <router-link :to="`/account/login`" v-else>{{ pageContent('enroll_now') }} <i class="icon-feather-chevrons-right"></i></router-link>
                            </div>  -->
                            <div class="course-button">
                                <router-link :to="`${$baseURL}user/course/${item.id}`" v-if="storeToken && item.is_enrolled === 'enrolled'">{{ pageContent('view_details') }} <i class="icon-feather-chevrons-right"></i></router-link>

                                <span v-else-if="storeToken && item.is_enrolled === 'requested'">{{ pageContent('requested_course') }} <i class="icon-feather-chevrons-right"></i></span>

                                <a href="javascript:void(0);" @click="enrollCourse(item.id, $event)" v-else-if="storeToken">{{ pageContent('enroll_now') }} <i class="icon-feather-chevrons-right"></i></a>
                                
                                <router-link :to="`${$baseURL}account/login`" v-else>{{ pageContent('enroll_now') }} <i class="icon-feather-chevrons-right"></i></router-link>
                            </div> 
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </fragment>
</template>

<script>
import RatingStar from '@/components/Course/RatingStar'
import Translation from '../../../public/translation.json'

export default {
    components: {RatingStar},
    props: {
        courses: Array,
        path: Object,
        box: String
    },
    data() {
        return {
            courseData: [],
            dirPath: '',
            boxSize: 'col-sm-4',
            ratings: 0,
            storeToken: this.$store.getters.storeToken || '',
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    methods: {
        pageContent(field) {
            return Translation.content[this.defaultLang][field]
        },
        getCourses() {
            let dispatch = 'getCourses'
            let params = {};
            // if(this.$store.getters.storeToken)
            //     dispatch = 'userCourses';

            params.clause = {
                popular_course: 'Y'
            }
          
            this.$store.dispatch(dispatch, {params}).then(res=>{
                if(res.data.status === true) {
                    this.courseData = res.data.data;
                    this.loaded = true
                }
            })
            .catch(err=>{
                console.log(err.response.data.error)
            })
        },
        enrollCourse(courseId, el) {
            let targetEl = el.target,
            params = {
                course_id: courseId
            }
           
            this.$http.post(`account/user/enroll-course`, params)
            .then(res=>{
                if(res.data.status === true) {
                   this.getCourses()
                }
            })
            .catch(err => {
                console.log(err.response);
            })
        }
    },
    created: function(){
        this.courseData = this.$props.courses
        this.dirPath = this.$props.path
        this.boxSize = this.$props.box
    },
    watch: {
        "$props.courses"(){
            this.courseData = this.$props.courses
            this.boxSize = this.$props.box
            this.dirPath = this.$props.path
        }
    }
}
</script>

<style scoped>
.course_content{min-height: 70px;}
</style>